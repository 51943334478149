import React, { useRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon, Intent } from "@blueprintjs/core";

import EsignatureIcon from "../esignature/esignatureIcon";
import { setOverlay } from "../../app/appSlice.js";
import { PREVIEW as OVERLAY_PREVIEW } from "../overlays/Overlays";
import FileWarning from "features/overlays/FileWarning";

import {
  selectSortedList,
} from "features/transfer/transferSlice";

import {
  getFileExtension,
  getMimeTypeFromExt,
  getTypeFromMime,
  isViewableFileExt,
  isSignableFileExt,
  isVideoFileExt,
} from "./utils";

import styles from "./Viewer.module.css";

export {
  getFileExtension,
  getMimeTypeFromExt,
  getTypeFromMime,
  isViewableFileExt,
  isSignableFileExt,
  isVideoFileExt,
};

export const Viewer = ({
  name,
  nav = [],
  nodeId,
  zoneId,
  onClose,
  Widget = null,
  cancel,
  setCancel,
  downloading,
  setDownloading,
}) => {
  const refBackend = useRef(null);
  const [fileIndex, setFileIndex] = useState(0);

  const noFoldersList = useSelector(selectSortedList);

  const targetNode = noFoldersList.find((node) => node.id === nodeId);
  const targetParentId = targetNode ? targetNode.parent : null;
  const sortedList = noFoldersList.filter(
    (node) => node.parent === targetParentId
  );

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (sortedList && sortedList.some((item) => item.id === nodeId)) {
        const index = sortedList.findIndex((item) => item.id === nodeId);
        setFileIndex(index);
      } else {
        throw new Error("Node ID not found in sorted list");
      }
    } catch (error) {
      console.error(error);
    }
  }, [nodeId, sortedList]);

  const goToNextDocument = useCallback(() => {
    const nextIndex = fileIndex + 1;
    if (fileIndex + 1 < sortedList.length) {
      const item = sortedList[nextIndex];
      setFileIndex(nextIndex);
      setCancel(false);
      setDownloading(false);
      onClose(false);
      dispatch(setOverlay({ overlay: OVERLAY_PREVIEW, file: item, zoneId }));
    }
  }, [
    fileIndex,
    sortedList,
    onClose,
    dispatch,
    zoneId,
    setCancel,
    setDownloading,
  ]);

  const goToPrevDocument = useCallback(() => {
    const prevIndex = fileIndex - 1;
    if (prevIndex >= 0) {
      const item = sortedList[prevIndex];
      setFileIndex(prevIndex);
      setCancel(false);
      setDownloading(false);
      onClose(false);
      dispatch(setOverlay({ overlay: OVERLAY_PREVIEW, file: item, zoneId }));
    }
  }, [
    fileIndex,
    sortedList,
    onClose,
    dispatch,
    zoneId,
    setCancel,
    setDownloading,
  ]);

  let content;

  for (const item of nav) {
    if (item.icon === "esignature") {
      item.icon = <EsignatureIcon />;
    } else if (item.icon === "endorsed") {
      item.icon = <Icon icon={item.icon} />;
    } else if (item.type === "zoom") {
      item.onIn = () => refBackend.current.zoomIn();
      item.onOut = () => refBackend.current.zoomOut();
    } else if (item.type === "file") {
      item.nextFile = () => goToNextDocument();
      item.prevFile = () => goToPrevDocument();
    } else if (item.type === "nav") {
      item.onPrev = () => refBackend.current.navPrev();
      item.onNext = () => refBackend.current.navNext();
    } else if (item.type === "end") {
      item.onEnd = () => refBackend.current.navEnd();
    }
  }

  content = (
    <ViewerBody
      name={name}
      nav={nav.filter((item) => item.type !== "nav" && item.type !== "end")}
      downloading={downloading}
      cancel={cancel}
    ></ViewerBody>
  );

  return content;
};

const ViewerBody = ({
  name,
  nav = [],
  downloading,
  cancel,
}) => {
  let row = [];
  for (const item of nav) {
    if (item.type === "expander") {
      row.push(<div className={styles.Expander} />);
    } else if (item.type === "title") {
      row.push(<div className={styles.Title}>{name}</div>);
    } else if (item.type === "button") {
      let className = styles.Button;
      if (item.primary) {
        className +=
        (downloading || cancel)
        ? ` ${styles.Disabled}`
        : ` ${styles.Primary}`;
      }
      row.push(
        <div className={className} onClick={() => item.onClick?.()}>
          {typeof item.icon === "string" ? (
            <Icon icon={item.icon} />
          ) : (
            item.icon
          )}
          <div>{item.text}</div>
        </div>
      );
    } else if (item.type === "cancel") {
      row.push(
        <>
          {downloading && !cancel && (
            <div className={styles.Button} onClick={() => item.onClick?.()}>
              <Icon icon={item.icon} />
              <div>{item.text}</div>
            </div>
          )}
        </>
      );
    } else if (item.type === "file") {
      row.push(
        <div>
          {downloading ? (
            <div className={styles.Status}>
              <Icon icon="download" intent={Intent.SUCCESS} />
            </div>
          ) : (
            <div className={styles.Nav}>
              <div onClick={() => item.prevFile?.()}>
                <Icon icon="arrow-left" />
              </div>
              <div onClick={() => item.nextFile?.()}>
                <Icon icon="arrow-right" />
              </div>
            </div>
          )}
        </div>
      );
    }
  }
  row = row.map((row, i) => React.cloneElement(row, { key: i }));
  return (
    <div className={styles.Outer}>
      <div className={styles.Navbar}>{row}</div>
      <div className={styles.Inner}>
        <div className={styles.Main}>
          <div className={styles.Info}>
            {cancel ? (
              <FileWarning
                isFileTooLarge={false}
                isNonPreviewable={false}
                isDownloadCanceled={true}
              />
            ) : (
              <FileWarning
                isFileTooLarge={true}
                isNonPreviewable={false}
                isDownloadCanceled={false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
