import { Callout, Intent } from "@blueprintjs/core";
import { classNames } from "utils";
import styles from "./Preview.module.css";

const INTENT = Intent.WARNING;
const ICON = "warning-sign";

const FileWarning = ({
  isFileTooLarge,
  isNonPreviewable,
  isDownloadCanceled,
}) => {
  const TITLE = isFileTooLarge
    ? "File too large to preview."
    : isNonPreviewable
    ? "Preview not available for this file type."
    : isDownloadCanceled
    ? "File download canceled."
    : "";

  const MESSAGE = isFileTooLarge
    ? "This file is too large to preview online. Please download it and view it locally."
    : isNonPreviewable
    ? "This file type is not supported for preview. Please upload a supported file format if you want to preview a file, or download and preview it locally."
    : isDownloadCanceled
    ? "The file download was canceled. Please try downloading the file again."
    : "";
  return (
    <Callout
      className={classNames(styles.callout)}
      intent={INTENT}
      icon={ICON}
      title={TITLE}
    >
      <span>{MESSAGE}</span>
    </Callout>
  );
};

export default FileWarning;
