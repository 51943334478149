import { useEffect, useState } from "react";
import { Spinner, SpinnerSize } from "@blueprintjs/core";
import styles from "./Preview.module.css";

const VIDEO_ERROR = "Your browser does not support the video tag.";

const Video = ({ theBlob }) => {
  const [blobUrl, setBlobUrl] = useState(null);

  useEffect(() => {
    if (theBlob) {
      try {
        const url = URL.createObjectURL(theBlob);
        setBlobUrl(url);

        return () => {
          URL.revokeObjectURL(url);
        };
      } catch (error) {
        console.error("Failed to create blob URL:", error);
      }
    }
  }, [theBlob]);

  return (
    <div className={styles.media}>
      <video className={styles.video} controls playsInline muted>
        {blobUrl ? (
          <>
            <source src={blobUrl} type="video/mp4" />
            <source src={blobUrl} type="video/quicktime" />
            <source src={blobUrl} type="video/webm" />
          </>
        ) : (
          <Spinner size={SpinnerSize.LARGE} />
        )}
        {VIDEO_ERROR}
      </video>
    </div>
  );
};

export default Video;
