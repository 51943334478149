import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Overlay, Spinner, SpinnerSize } from "@blueprintjs/core";
import {
  Viewer,
  getFileExtension,
  getMimeTypeFromExt,
  getTypeFromMime,
} from "../viewer/ViewerWarning.jsx";
import {
  startDownload,
  setAbortStream,
  selectProgress,
} from "../transfer/transferSlice.js";
import { appState } from "../../app/appState.js";
import {
  FILE_DOWNLOAD,
  ATTESTATION_REQUEST,
  ATTESTATION_APPROVE,
  ATTESTATION_REJECT,
} from "../../app/permissions.js";
import WidgetAttestation from "./WidgetAttestation.js";
import styles from "./Preview.module.css";

const {
  selectors: { selectGraph },
} = appState;

const WidgetNull = () => null;

export default ({
  dispatch,
  file,
  node,
  permissions,
  active,
  close,
  nodeId,
  zoneId,
}) => {
  const { nodes } = useSelector(selectGraph);
  const progress = useSelector(selectProgress);

  const [widget, setWidget] = useState(null);
  const [cancel, setCancel] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (progress) {
      const ratio = progress[file?.id];
      if (ratio?.progress === 1) {
        setDownloading(false);
      }
    }
  }, [progress]);

  file = file || node;
  nodeId = nodeId || file?.id;

  let theNode = file;

  if (nodeId && nodeId in nodes) {
    theNode = nodes[nodeId];
  }

  let ext, mime, type;

  if (theNode) {
    ext = getFileExtension(theNode.name);
    mime = getMimeTypeFromExt(ext);
    type = getTypeFromMime(mime);
  }

  const isUnknown = type === "unknown";

  const theWidget = widget;

  const nullWidget = theWidget == WidgetNull;

  const canAttestionRequest = permissions[ATTESTATION_REQUEST];
  const canAttestionRespond =
    permissions[ATTESTATION_APPROVE] || permissions[ATTESTATION_REJECT];

  //const instaAttest = attestation && (canAttestionRequest || canAttestionRespond);

  const attestionWidget = theWidget == WidgetAttestation;

  const showAttestation =
    !attestionWidget && zoneId && (canAttestionRequest || canAttestionRespond);

  const onAttest =
    showAttestation && (() => setWidget(() => WidgetAttestation));

  const onClose = (closePreview = true) => {
    if (!cancel && downloading) {
      dispatch(setAbortStream({ abort: true, id: theNode.storageId }));
    }

    setDownloading(false);
    setCancel(false);

    if (closePreview) {
      close();
    }
  };

  const onCancel = () => {
    setDownloading(false);
    setCancel(true);
    dispatch(setAbortStream({ abort: true, id: theNode.storageId }));
  };

  const onDownload = async () => {
    if (permissions?.[FILE_DOWNLOAD]) {
      if (!downloading && !cancel) {
        setDownloading(true);
        await dispatch(
          startDownload(theNode, FILE_DOWNLOAD, null, undefined, true)
        );
      }
    }
  };

  const nav = [
    {
      type: "button",
      icon: downloading ? (
        <Spinner size={SpinnerSize.SMALL} />
      ) : cancel ? (
        "disable"
      ) : (
        "download"
      ),
      text: downloading ? "Downloading" : cancel ? "Canceled" : "Download",
      primary: !(downloading || cancel),
      onClick: () => onDownload(),
    },
    /* ...(onAttest && !isUnknown
      ? [
          {
            type: "button",
            icon: "endorsed",
            text: "Attestations",
            attestation: true,
            onClick: () => onAttest(),
          },
        ]
      : []), */
    { type: "title" },
    { type: "expander" },
    { type: "nav" },
    {
      type: "file",
    },
    {
      type: "cancel",
      text: "Cancel",
      icon: "disable",
      onClick: () => onCancel(),
    },
    {
      type: "button",
      icon: "cross",
      text: "Close",
      onClick: () => onClose(),
    },
  ];

  return (
    <Overlay
      title="Preview"
      isOpen={active}
      className={styles.overlay}
      hasBackdrop={false}
    >
      <Viewer
        name={theNode?.name}
        nav={nav}
        nodeId={nodeId}
        zoneId={zoneId}
        onClose={onClose}
        Widget={!nullWidget && theWidget}
        cancel={cancel}
        setCancel={setCancel}
        downloading={downloading}
        setDownloading={setDownloading}
      />
    </Overlay>
  );
};
