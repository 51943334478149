import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOverlay, selectOverlay } from "../../app/appSlice.js";
import { appState } from "../../app/appState.js";
import { values } from "utils";
import { getFileExtension } from "../viewer/ViewerWarning.jsx";
import Preview from "./Preview.js";
import PreviewWarning from "./PreviewWarning.js";

const {
  selectors: { selectPermissions },
} = appState;

export const PREVIEW = "PREVIEW";
export const PREVIEW_WARNING = "PREVIEW_WARNING";
export const NOT_SIGNED = "Not signed";
export const NOT_AVAILABLE = "Not available";
export const NO_ORDER = "No order";

export default () => {
  const dispatch = useDispatch();
  const { overlay, ...args } = useSelector(selectOverlay);
  const permissions = useSelector(selectPermissions);
  const file = values(args);

  const [fileLoading, setFileLoading] = useState(false);
  const fileSize = file[0]?.meta?.size;

  const isMobile =
    /Mobi|Android|iPhone|iPod|BlackBerry|IEMobile|iPad|Opera Mini/i.test(
      navigator.userAgent
    );

  const validDocExtensions = ["pdf", "docx", "xlsx", "pptx"];
  const fileExtension = getFileExtension(file[0]?.name ?? "");
  const isDoc = validDocExtensions.some((ext) => fileExtension.endsWith(ext));

  const isFileTooLargeToPreview = isDoc
    ? fileSize > 50000000
    : isMobile
    ? fileSize > 150000000
    : fileSize > 300000000;

  const props = {
    ...args,
    dispatch,
    permissions,
    close: () => dispatch(setOverlay()),
    fileLoading,
    setFileLoading,
  };

  return (
    <>
      {isFileTooLargeToPreview ? (
        <PreviewWarning active={overlay === PREVIEW} {...props} />
      ) : (
        <Preview active={overlay === PREVIEW} {...props} />
      )}
    </>
  );
};
