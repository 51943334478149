import { useRef, useEffect } from "react";
import styles from "./main.module.css";

export default function useFileInput() {
  const callback = useRef(null);
  const input = useRef(null);

  useEffect(() => {
    const el = document.createElement("input");
    el.type = "file";
    el.multiple = true;
    el.className = styles.hiddenFileInput;

    el.addEventListener("change", async (e) => {
      const { files } = e.target;
      if (callback.current) {
        callback.current(files);
      }

      if (e && e.target) {
        e.target.value = "";
      }
      el.parentNode.removeChild(el);
    });

    document.body.appendChild(el);
    input.current = el;

    return () => {
      if (!callback.current) {
        el.parentNode.removeChild(el);
      }
    };
  }, []);

  return (cb) => {
    if (input.current) {
      input.current.click();
      callback.current = cb;
    }
  };
}
